import { tns } from 'tiny-slider/src/tiny-slider';
const newsCards = document.querySelectorAll('.home .archive-listing__list--post');

if (newsCards.length > 0) {
    newsCards.forEach((newsCard) => {

      const slider = tns({
        container: newsCard,
        items: 3,
        slideBy: '1',
        fixedWidth: false,
        autoplay: false,
        controls: true,
        controlsText: [
            '<svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none"><path d="M19.335 12L5.33496 12" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><path d="M11.335 18L5.33496 12L11.335 6" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>',
            '<svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none"><path d="M5.33496 12H19.335" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><path d="M13.335 6L19.335 12L13.335 18" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>'],
        nav: false,
        loop: false,
        swipeAngle: false,
        gutter: 20,
        responsive: {
            0: {
              items: 1,
              gutter: 10
            },
            599: {
              items: 2,
              gutter: 10
            }
          },
      });
      slider.events.on('transitionEnd', function(slider,event) {
        for (let $el of slider.slideItems) {
          $el.ariaHidden = false;
          $el.removeAttribute('tabindex');
      }
      });
    });
  }
